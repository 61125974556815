import { Atom, Mod } from ":mods";
import { createEffect, createMemo, createSignal, Show } from "solid-js";
import { AMaterialTaskContent, MaterialTaskDesignView } from "../../../../../../modules/courses/models";
import { TEAM_BOX_COLORS, TEAM_CELL_DATA } from "../const";
import { CreateMemberCard } from "../components/per-topic/delivery-team-old/create-member-card";
import { ShowMemberCard } from "../components/per-topic/delivery-team-old/show-member-card";
import { TeamCardProps } from "../model";

type RecievedPayload = AMaterialTaskContent<
  [
    {
      team_list: {
        name: string;
        position: string;
        update_count: number;
        is_volunteer: boolean;
      }[][];
    }
  ],
  true
>;
type RequestPayload = AMaterialTaskContent<
  [
    {
      team_list: {
        name: string;
        position: string;
        update_count: number;
        is_volunteer: boolean;
      }[][];
    }
  ],
  true
>;
type RequestPayloadSection = Partial<RequestPayload[0]>;
type RequestPayloadSectionContent = Partial<RequestPayloadSection["content"]>;
type RequestPayloadSectionContentItem = Partial<RequestPayloadSectionContent[0]>;

export default function (props: MaterialTaskDesignView<RecievedPayload, RequestPayloadSectionContent>) {
  const cellData = TEAM_CELL_DATA;
  const max_col = 3;
  const max_row = 4;
  const centerIdx = Math.floor(max_col / 2);
  const skeleton = Array.from(Array(max_row).fill(1), () => new Array(max_col).fill({}));
  skeleton[0][centerIdx] = cellData;

  const [teamList, setTeamList] = createSignal(skeleton);

  // track parent content
  const content = createMemo(() => {
    // on content updated or discarded
    return props.elements.data.content as RecievedPayload;
  });

  createEffect(() => {
    // @ts-ignore
    const { team_list } = content()[0].content?.[0] || {};
    if (team_list) setTeamList(team_list);
  });

  console.log("content()[0].content:: ", content()[0].content);
  function onDataChange(row: number, col: number, props: RequestPayloadSectionContentItem) {
    // @ts-ignore
    const items =
      content()[0].content?.length > 1
        ? content()[0]?.content
        : (content()[0]?.content[0]?.team_list as any as RequestPayloadSectionContent);
    console.log("props and function:: ", props);
    console.log("items[row][col] ", items[row][col]);
    //@ts-ignore
    handleCardChange(row, col, props);
    if (items === undefined || items === null) {
      console.log("Items Team were undefined");
      return;
    }

    console.log("row in update function:: ", row);
    console.log("col in update function:: ", col);

    if (Object.keys(props).length === 0) {
      // @ts-ignore
      items[row][col] = {};
    } else {
      items[row][col] = { ...items[row][col], ...props };
      // @ts-ignore
      items[row][col].is_volunteer = false;
    }

    // pass data to update
    console.log("d :: ", items);
    // @ts-ignore
    onDataUpdate(items);
  }

  function onDataUpdate(data: RequestPayloadSectionContent) {
    const formData = new FormData();
    const payload = JSON.stringify([{ team_list: data }]);

    formData.append("data", payload);

    // formData.append("data", JSON.stringify(data));
    // @ts-ignore
    props.events?.onDataUpdate?.(formData, [{ team_list: data }]);
  }

  const handleCardChange = (r: number, c: number, data: TeamCardProps) => {
    let newSkeleton = teamList();
    newSkeleton[r][c] = data;
    setTeamList(newSkeleton);
  };
  const onLeftClicked = (r: number, c: number) => {
    if (c - 1 >= 0) {
      const list = [...teamList()];
      list[r][c - 1] = cellData;

      setTeamList([...list]);
      // onDataChange(r, c, { image: e })
    }
  };
  const onRightClicked = (r: number, c: number) => {
    if (c + 1 < max_col) {
      const list = [...teamList()];
      list[r][c + 1] = cellData;
      setTeamList([...list]);
    }
  };
  const onDownClicked = (r: number, c: number) => {
    if (r + 1 < max_row) {
      const list = [...teamList()];
      list[r + 1][c] = cellData;
      setTeamList([...list]);
    }
  };

  const onConfirmDeleteBox = (r: number, c: number) => {
    let newSkeleton = [...teamList()];
    newSkeleton[r][c] = {};
    setTeamList(newSkeleton);
    // @ts-ignore
    onDataChange(r, c, {});
    return false;
  };
  const isEmptyObject = (obj: Object) => {
    if (!obj) return false;
    return Object.keys(obj).length == 0;
  };

  const row_gap = "1rem";
  return (
    <div class="felx flex-col gap-40px">
      <h1 class="w-full text-36px font-700 px-10px py-30px overflow-hidden leading-43px">
        {content()[0]?.details?.title}
      </h1>
      <section class="pb-150px relative ">
        <div
          // class="relative flex flex-col justify-center w-full"
          class="grid w-full h-full px-2"
          style={{
            "grid-template-columns": "repeat(1, 100%)", // fixed
            "row-gap": row_gap,
            // "grid-template-rows": "repeat(3, 1fr)", // minmax(150px, 1fr)
          }}
        >
          {teamList()?.map((row, r) => {
            const col_gap = "2rem";
            return (
              <div
                id={"row-" + r + 1}
                // class="grid justify-center gap-10px h-90px w-full debug$"
                class="grid !border-dashed w-full h-full items-center justify-center "
                style={{
                  "grid-row": r + 1, // this rows place in the outer grid
                  "column-gap": col_gap,
                  // "grid-template-columns": "repeat(3, calc(96%/3))",
                  // "grid-template-rows": "repeat(1, 100%)", //"repeat(1, 100%)", // minmax(150px, 1fr)
                }}
              >
                {row?.map((box: TeamCardProps, c) => {
                  return (
                    <div
                      // class="flex items-baseline justify-center h-90px my-5px"
                      class="relative flex items-center justify-center h-90px w-10rem min-w-full self-center"
                      style={{
                        // "min-width": "100%",
                        "grid-column": c + 1,
                      }}
                    >
                      <Show when={!isEmptyObject(box)}>
                        {/* show the line when the col bigger than 0 and less than or equal the max col and the object has value */}
                        <Show when={c > 0 && c <= max_col && !isEmptyObject(row[c - 1])}>
                          <hr
                            class="absolute bottom-50% h-[2px] bg-black "
                            style={{
                              width: col_gap,
                              left: `-${col_gap}`,
                            }}
                          />
                        </Show>
                        <CreateMemberCard
                          editable={props.options.editable}
                          elements={{
                            data: box,
                          }}
                          options={{
                            showRight: c + 1 < max_col && isEmptyObject(row[c + 1]),
                            showLeft: c - 1 >= 0 && isEmptyObject(row[c - 1]),
                            showDown: r + 1 < max_row && centerIdx == c && isEmptyObject(teamList()[r + 1][c]),
                            linkDown:
                              (max_row <= r + 1 ? false : !isEmptyObject(teamList()?.[r + 1][c])) && centerIdx == c,
                            bgColor: TEAM_BOX_COLORS[box?.update_count],
                            hideDeleteIcon: c === centerIdx && r === 0,
                          }}
                          events={{
                            onDown: () => onDownClicked(r, c),
                            onRight: () => onRightClicked(r, c),
                            onLeft: () => onLeftClicked(r, c),
                            // @ts-ignore
                            onChange: (data) => onDataChange(r, c, data),
                            onConfirm: () => onConfirmDeleteBox(r, c),
                          }}
                        />
                        {/* <Show when={props.options.editable}>
                        </Show>
                        <Show when={!props.options.editable}>
                          <ShowMemberCard
                            elements={{
                              data: box,
                            }}
                            options={{
                              showRight: c + 1 < max_col && isEmptyObject(row[c + 1]),
                              showLeft: c - 1 >= 0 && isEmptyObject(row[c - 1]),
                              showDown: r + 1 < max_row && centerIdx == c && isEmptyObject(teamList()[r + 1][c]),
                              linkDown:
                                (max_row <= r + 1 ? false : !isEmptyObject(teamList()?.[r + 1][c])) && centerIdx == c,
                              bgColor: TEAM_BOX_COLORS[box?.update_count],
                              hideDeleteIcon: c === centerIdx && r === 0,
                            }}
                          />
                        </Show> */}
                      </Show>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </section>
    </div>
  );
}
